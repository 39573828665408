export const ContainerStyled = `
  margin-left: auto;
  margin-right: auto;

  max-width: 360px;
  padding-left: 35px;
  padding-right: 35px;

  @media screen and (min-width: 768px) {
    max-width: 768px;
    padding-left: 58px;
    padding-right: 58px;
  }
  
  @media screen and (min-width: 1240px) {
    max-width: 1220px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
